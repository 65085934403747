import * as React from "react";
import "../assets/scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Acnavbar from "../components/Acnavbar";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Workwithus from "../components/Workwithus";
import Footer from "../components/footer";
import Seo from "../shared/seo";
import Contact from "../components/Contact";
import Wrapper from "../components/Wrapper";
import OurClients from "../components/OurClients";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const IndexPage = () => {
  return (
    <Wrapper>
      <Seo
        title="Softobotics - Innovative IT Solutions for Business Success"
        description="Transform your business with Softobotics' innovative IT solutions. We specialize in software development, automation, and digital transformation."
      />
      <Acnavbar home={true} pathname={""} logo={""} logoLogic={false} />
      <Hero />
      <Services />
      <Workwithus />
      <OurClients/>
      <div className="contact-home-border">
        <Contact />
      </div>
      <Footer />

      {/*<Awards />*/}
    </Wrapper>
  );
};

export default IndexPage;
