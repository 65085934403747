import React, { useState,useRef,useEffect } from "react";
import { Container,Tab, Tabs } from "react-bootstrap";
import ProductCard from "./ServicesCard";
import serviceOffering from "../json/service-product.json"

function Services() {
  // const [tabKey, initTabKey] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  
  const handleTabSelect = (selectedIndex: any) => {
    setActiveTab(selectedIndex);
  };

  
  useEffect(() => {
    if (tabsRef.current && window.innerWidth < 768) {
      const scrollAmount = 60 * activeTab;
      tabsRef.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }
  }, [activeTab]);
  return (
    <div id="services" className="common-light-background  bg-cover position-relative pb-md-4">
      <Container className="py-3 pt-lg-3  tabs-container pt-md-4 ">
        <div className="d-flex flex-column">
          <h2 className="fnt-20  fnt-lg-32 fw-700 py-1 text-center text-white mt-md-3">
            Service Offerings
          </h2>
          <div className="text-center d-flex">
            <p className="text-center text-white w-100 text-wrap mb-0 mb-lg-3 fnt-14 fnt-lg-16">
              Experience a service like no other. Our commitment to customer satisfaction sets us apart, delivering results that make a difference.
            </p>
          </div>
        </div>


        <Tabs
          activeKey={activeTab} onSelect={handleTabSelect}
          className="border-0 fw-700 custom-tab-scroll justify-content-lg-center services-tabs"
        >
          {serviceOffering.map((offering: any, tabIndex: any) => (
            <Tab
              className="tab-section product-tab"
              key={tabIndex}
              ref={tabsRef}
              eventKey={tabIndex}
              title={offering.header} >
              <div className="mt-3 mt-lg-4">
                <div className="row">
                  {offering?.serviceCardData?.serviceCardDetails?.map((card: any, cardIndex: any) => (
                    <div key={cardIndex} className='col-md-6 col-lg-4 tab-min-height'>
                      <ProductCard  {...card} />
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
}

export default Services;
