import React from "react";
import Card from "react-bootstrap/Card";
import { Button, Container } from "react-bootstrap";
import { Link } from "gatsby";
import wellness from "../assets/images/wellness.webp";
import leave from "../assets/images/leave.webp";
import timeoff from "../assets/images/timeoff.webp";
import compensation from "../assets/images/compensation.webp";
import MeetOurTeam from "./MeetOurTeam";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"


function Workwithus() {
  return (
    <div className="bg-cover position-relative" id="workwithus">
      <Container className="">
        <div className="pt-4 pt-md-5 pb-2 pb-lg-4">
          <h2 className="fnt-20 fnt-lg-32 fw-700  pb-1 text-center">
            Work with us
          </h2>
          <div className="d-flex justify-content-center">
            <p className="text-center mb-3 mb-md-3 text-wrap fnt-14 fnt-lg-16">
              Be a part of our fast-paced tech team & enjoy unlimited growth opportunities
            </p>
          </div>
          <div className="d-flex col-12 flex-lg-row  flex-column mb-lg-3 ">
            <div className="col-lg-6 ">
              <MeetOurTeam home={true}
                background="white-background"
                textColor="text-black"
              />
            </div>
            <div className="col-lg-6  d-flex flex-column flex-lg-row">
              <div className="row tab-section mt-4">
                <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 ps-xl-0 ps-xxl-0">
                  <Card className="h-100 common-card-effect">
                    <Card.Body>
                      <Card.Title className="d-flex">
                        <LazyLoadImage effect="blur" src={compensation} alt="Compensation Icon" />
                        <div className="d-flex align-items-center ms-3">
                          <div className="fw-700 fnt-18 ">Good Compensation</div>
                        </div>
                      </Card.Title>
                      <Card.Text className="fnt-14 fnt-lg-16">
                        Above-market compensation and equity grants and
                        performance-based bonus programs.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-12 col-lg-6 mb-3 col-xl-6 col-xxl-6 ps-xl-0 ps-xxl-0">
                  <Card className="h-100 common-card-effect">
                    <Card.Body>
                      <Card.Title className="d-flex">
                        <LazyLoadImage effect="blur" src={timeoff} alt="Timeoff Icon" />
                        <div className="d-flex align-items-center ms-3">
                          <div className="fw-700 fnt-18 ">Paid time off</div>
                        </div>
                      </Card.Title>
                      <Card.Text className="fnt-14 fnt-lg-16">
                        In addition to standard holidays, Softobotics offers competitive
                        paid time off and regular company-wide wellness days.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 ps-xl-0 ps-xxl-0">
                  <Card className="h-100 common-card-effect">
                    <Card.Body>
                      <Card.Title className="d-flex">
                        <LazyLoadImage effect="blur" src={leave} alt="logo" />
                        <div className="d-flex align-items-center ms-3">
                          <div className="fw-700 fnt-18">Parental leave</div>
                        </div>
                      </Card.Title>
                      <Card.Text className="fnt-14 fnt-lg-16">
                        Softobotics offers paid parental leave to all employees
                        irrespective of gender when they are going through one
                        of the best moments in their life and becoming a parent.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 ps-xl-0 ps-xxl-0">
                  <Card className="h-100 common-card-effect">
                    <Card.Body>
                      <Card.Title className="d-flex">
                        <LazyLoadImage effect="blur" src={wellness} alt="Wellness Icon" />
                        <div className="d-flex align-items-center ms-3">
                          <div className="fw-700 fnt-18">Wellness benefits</div>
                        </div>
                      </Card.Title>
                      <Card.Text className="fnt-14 fnt-lg-16">
                        Softobotics believes that employee wellness is paramount.
                        We aim to create a healthy work environment to keep the
                        employees health and wellness above everything else.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/careers">
              <Button className="btn-outline-secondary btn mx-2 mb-4 cjo-button fnt-14 fnt-lg-16">
                Current Job Openings
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Workwithus;
