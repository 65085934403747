import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
// import mobileViewImage1 from "../assets/images/hero_image_1.webp";
// import mobileViewImage2 from "../assets/images/hero_image_2.webp";
// import mobileViewImage3 from "../assets/images/hero_image_3.webp";
import softoboticsBg from "../assets/images/softobotics-bg.svg";

function Hero() {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isImageVisible, setImageVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);


  return (
    <div
      ref={imageRef}
      className="hero-background bg-cover min-vh-lg-80 vhmax-sm-550 min-vw-80 pos-relative overflow-hidden"
      id="aboutus"
      style={{
        backgroundImage: `url(${softoboticsBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <div className="pt-3 pt-lg-5"></div>
      <div className="container d-flex flex-column justify-content-center mt-md-5 pt-md-5 pt-lg-3 mt-lg-5 pt-md-5">
        <div className="mt-lg-2 mt-xxl-5 pt-lg-3 pt-xxl-3 pt-6 mt-6 mt-md-0 pt-md-0 mb-3 mb-md-0">
          <h1 className="fnt-24 fnt-md-36 fnt-lg-40 fw-700 text-white pt-md-3 hero-h1-width mt-3 mt-md-0 mt-xxl-3 px-2 ps-0 mobile-text w-100 mb-md-0 text-center">
            Helping businesses to{" "}
            <span className="color-lightGreen"> Innovate</span>,
            <span className="color-lightGreen d-md-block">
              {" "}
              Automate <span className="color-white">and</span> Optimize
              <span className="color-white">.</span>
            </span>
          </h1>
        </div>

        <div className="d-flex flex-column mt-md-3">
          <div className="d-md-flex justify-content-md-center">
          <p className="fnt-14 fnt-lg-16 mb-3 px-md-0 cust-tab-text text-white text-center lh-md-30">
            Accelerate your business's growth and fuel innovation with our
            advanced technology solutions. We specialize in leveraging
            cutting-edge technologies to unlock your organization's full
            potential. Partner with us to stay ahead of the competition,
            navigate digital transformations, and achieve remarkable success.
          </p>
          </div>
         
          <div>
            <div className="mb-5 text-center">
              <Link to="/contact-us/">
                <button className="btn px-4 px-md-5 py-2 mt-2 mt-md-4 mb-md-5 btn-contact-us border-0 mx-auto fnt-14 fnt-lg-16">
                  Connect Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
